import { Component, Output, EventEmitter, OnDestroy, OnInit, AfterViewInit, ElementRef, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LayoutService } from '../services/layout.service';
import { Subscription } from 'rxjs';
import { ConfigService } from '../services/config.service';
import { Router } from "@angular/router";
import { AuthentificationService } from "app/services/authentification.service";
import { AdminService } from "app/services/admin.service";
import Swal from 'sweetalert2'
import { NotificationsService } from "app/services/notifications.service";
import { UsersService } from 'app/services/users.service';
import { ModalDismissReasons, NgbModal } from "@ng-bootstrap/ng-bootstrap";


@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"]
})
export class NavbarComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('name', {static: true}) name: ElementRef;

  user = { user_name: "" };
  lastnotif : any ;
  counts ;
  counts_user;
  objs = [];
  list_users =[];
  // name;
  obj : any;
  currentLang = "en";
  toggleClass = "ft-maximize";
  placement = "bottom-right";
  public isCollapsed = true;
  layoutSub: Subscription;
  public page = 1;
  pageSize: number = 10;
  public collectionSize = 0;
  public countt = 0;
  connect_user: any;
  listnotif_user = [];

  @Output()
  toggleHideSidebar = new EventEmitter<Object>();

  public config: any = {};

  constructor(public translate: TranslateService, private layoutService: LayoutService, private configService:ConfigService,    private router: Router, private userService : UsersService,nameElement: ElementRef,
    private adminService: AdminService,
    private logoutservice: AuthentificationService,
    private messagingService: NotificationsService,private notifserice: NotificationsService) {
    const browserLang: string = translate.getBrowserLang();
    translate.use(browserLang.match(/en|es|pt|de/) ? browserLang : "en");

    this.layoutSub = layoutService.changeEmitted$.subscribe(
      direction => {
        const dir = direction.direction;
        if (dir === "rtl") {
          this.placement = "bottom-left";
        } else if (dir === "ltr") {
          this.placement = "bottom-right";
        }
      });

  }

  ngOnInit() {
    this.config = this.configService.templateConf;
    this.connect_user = JSON.parse(localStorage.getItem("currentUser"));

    this.user.user_name = localStorage.getItem("user_name");
    this.count();
    this.count_user();
    this.all(0);
    this. get_all_user(1, 100)
    this.obj = {'user_name':this.name.nativeElement.value, }
    console.log('nameeeee',this.name.nativeElement.value)
  }
  fn_sendJson(item:any){
    this.router.navigate(['/users']);
    this.userService.shareDate({"data": JSON.stringify(item)})
   }
  count(){
    this.messagingService.get_count().subscribe(result=>{
  this.counts = result
    })
  }
  count_user(){
    this.messagingService.get_count_use().subscribe(result=>{
  this.counts_user = result
    })
  }
  all(p){
    this.messagingService.get_all_notif_no_pagination(p,10).subscribe(result=>{
  this.lastnotif = result["notifications"]
  console.log("last one ", this.lastnotif)
    })
  }
  logout() {
    Swal.fire({
      title: "هل انت متأكد؟",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "نعم !",
      cancelButtonText: " إلغاء ",
    }).then((result) => {
      if (result.value) {
        this.logoutservice.LogOut();
        this.logoutservice.removeToken();
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
        console.log("okkk");
      }
    });
  }
  ngAfterViewInit() {
    if(this.config.layout.dir) {
      setTimeout(() => {
        const dir = this.config.layout.dir;
        if (dir === "rtl") {
          this.placement = "bottom-left";
        } else if (dir === "ltr") {
          this.placement = "bottom-right";
        }
      }, 0);
     
    }
  }

  ngOnDestroy() {
    if (this.layoutSub) {
      this.layoutSub.unsubscribe();
    }
  }

  ChangeLanguage(language: string) {
    this.translate.use(language);
  }

  ToggleClass() {
    if (this.toggleClass === "ft-maximize") {
      this.toggleClass = "ft-minimize";
    } else {
      this.toggleClass = "ft-maximize";
    }
  }

  toggleNotificationSidebar() {
    this.layoutService.emitNotiSidebarChange(true);
  }

  toggleSidebar() {
    const appSidebar = document.getElementsByClassName("app-sidebar")[0];
    if (appSidebar.classList.contains("hide-sidebar")) {
      this.toggleHideSidebar.emit(false);
    } else {
      this.toggleHideSidebar.emit(true);
    }
  }
  is_Admin() {
    return this.connect_user.type_user === "admin" ? true : false;
  }

  filtering_user() {
    this.obj = {'user_name':this.name.nativeElement.value, }
    console.log('nameeeee',this.name.nativeElement.value)
    if (this.objs.length === 0) {
      this.objs.push(this.obj);
    } else if (!this.objs.some(item => item.user_name === this.obj.user_name)) {
      this.objs.push(this.obj);
    } else {
      this.objs = this.objs.filter((item) => item.user_name != this.obj.user_name);
    }

    console.log("okk objs ", this.objs);
    const data = {
      obj: this.objs,
    };
     if(this.objs.length>0){

      console.log("data of if ", data); 
    this.userService.filtering_userr(data).subscribe((result) => {
      console.log("resultt", result);
      this.list_users = result["data"]["employees"];
      console.log("aaaddddddddddddddd", this.list_users);
      this.router.navigate(['users/recherche']);
      // this.userService.shareDate({"data": JSON.stringify(this.list_users)})
      localStorage.setItem('testObject', JSON.stringify(this.list_users));
      Swal.fire({
        position: "center",
        icon: "success",
        title: "تم  التصفية  بنجاح ",
        showConfirmButton: false,
        timer: 1500,
      });
    });}
    else {
      
  }
  }
  is_Employe_Manager_Sales() {
    return this.connect_user.job.type == 2 ? true : false;
  }
  get_all_user(p, per) {
    this.notifserice.get_all_notif_user(p, per).subscribe((reslt) => {
      this.listnotif_user = reslt["notifications"];
      this.listnotif_user.sort((a, b) => {
        if (a.seen > b.seen) {
          return 1;
        } else if (a.seen < b.seen) {
          return -1;
        } else {
          return 0;
        }
      });
      console.log("list of all notif useeeeeerr+++", this.listnotif_user);
      
    });
  }
}
