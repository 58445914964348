import { NotificationsService } from './services/notifications.service';
import { AuthentificationService } from "app/services/authentification.service";
import { Component, ViewContainerRef, OnInit, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";
import { Router, NavigationEnd, NavigationStart } from "@angular/router";
import { filter } from "rxjs/operators";
import { NavigationEvent } from "@ng-bootstrap/ng-bootstrap/datepicker/datepicker-view-model";
import { SocketService } from './services/socket.service';
import { Socket } from 'ngx-socket-io';


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {

    subscription: Subscription;
    event$;
    message;
    constructor(
        private router: Router,
        private socket: Socket,
        private authenticationService: AuthentificationService,
        private messagingService: NotificationsService
      ) {



        this.event$ = this.router.events.subscribe((event: any) => {
          if (event instanceof NavigationStart) {
            console.log(event.url);
            let isAuth = this.authenticationService.isLoggedin();
            if (isAuth && event.url == "/pages/login") {
              this.router.navigate(["/management"]);
            }
          }
        });
      }
    

    ngOnInit() {

        this.subscription = this.router.events
            .pipe(
                filter(event => event instanceof NavigationEnd)
            )
            .subscribe(() => window.scrollTo(0, 0));
    }


    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }



}