import { RouteInfo } from "./sidebar.metadata";

//Sidebar menu Routes and data

export  const ROUTES: RouteInfo[] = [
  
  {
    path: "/management",
    title: "الرئيسية ",
    icon: "far fa-th-large",
    class: "",
    badge: "",
    badgeClass: "badge badge-pill badge-danger float-right mr-1 mt-1",
    isExternalLink: false,
    submenu: [],
  },
  {
    path: "",
    title: "إدارة المستخدمين ",
    icon: "far fa-poll-people",
    class: "has-sub",
    badge: "",
    badgeClass: "badge badge-pill badge-danger float-right mr-1 mt-1",
    isExternalLink: false,
    submenu: [
      {
        path: "/users",
        title: "إدارة الموظفين",
        icon: "far fa-user",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      },
      {
        path: "/jobs",
        title: "إدارة الوظائف",
        icon: "far fa-users-cog",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      },
      {
        path: "/missions",
        title: "إدارة الصلاحيات",
        icon: "far fa-user-lock",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      },
    ],
  },
  {
    path: "",
    title: "إدارة مقدمي الخدمة ",
    icon: "far fa-briefcase",
    class: "has-sub",
    badge: "",
    badgeClass: "badge badge-pill badge-danger float-right mr-1 mt-1",
    isExternalLink: false,
    submenu: [
      {
        path: "/serviceprovider",
        title: "إدارة المراكز الطبية ",
        icon: "far fa-folder-tree",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      },
      {
        path: "/serviceprovider/new-branch",
        title: " إنشاء فرع ",
        icon: "far fa-folder-tree",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      },

      {
        path: "/service",
        title: "خدمات المراكز ",
        icon: "far fa-clipboard-list",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      },
      {
        path: "/service/allServ",
        title: " الخدمات ",
        icon: "far fa-clipboard-list-check",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      },
      {
        path: "/contracts",
        title: " العقود ",
        icon: "far fa-file-contract",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      },
    ],
  },
  {
    path: "",
    title: "إدارة العملاء ",
    icon: "far fa-users-class",
    class: "has-sub",
    badge: "",
    badgeClass: "badge badge-pill badge-danger float-right mr-1 mt-1",
    isExternalLink: false,
    submenu: [
      {
        path: "/client",
        title: " العملاء",
        icon: "far fa-users-crown",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      },
      {
        path: "/client/membreship",
        title: "التحقق من العضوية",
        icon: "far fa-user-check",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      },

      {
        path: "/serviceprovider/suggestion",
        title: "الإنضمام أو إقتراح مركز",
        icon: "far fa-university",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      },

      {
        path: "",
        title: "إدارة التوظيف",
        icon: "far fa-user-tie",
        class: "has-sub",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [
          {
            path: "/employment/candidacy",
            title: "مطالب الترشح",
            icon: "far fa-users-cog",
            class: "",
            badge: "",
            badgeClass: "",
            isExternalLink: false,
            submenu: [],
          },
          // { path: '/employment/demand', title: 'مطلب انظمام كتاجر', icon: 'far fa-book-user', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        ],
      },
    ],
  },
  {
    path: "",
    title: "إدارة الطلبات ",
    icon: "far fa-game-board",
    class: "has-sub",
    badge: "",
    badgeClass: "badge badge-pill badge-danger float-right mr-1 mt-1",
    isExternalLink: false,
    submenu: [
      {
        path: "/cards/cardrequest",
        title: "الطلبات",
        icon: "far fa-address-card",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      },
      {
        path: "/cards/cancelledCard",
        title: " الطلبات المرفوضة",
        icon: "far fa-tasks",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      },
      {
        path: "/cards",
        title: " البطاقات",
        icon: "far fa-credit-card-blank",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      },
      {
        path: "/cards/typecardlist",
        title: " أنواع البطاقات",
        icon: "far fa-credit-card-front",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      },
    ],
  },
  {
    path: "",
    title: "الكوبونات و العروض  ",
    icon: "far fa-gifts",
    class: "has-sub",
    badge: "",
    badgeClass: "badge badge-pill badge-danger float-right mr-1 mt-1",
    isExternalLink: false,
    submenu: [
      {
        path: "/coupons",
        title: " الكوبونات",
        icon: "far fa-gift",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      },
      {
        path: "/coupons/list-requests",
        title: " العروض ",
        icon: "far fa-file-search",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      },
    ],
  },
  {
    path: "",
    title: "إدارة المحافظ",
    icon: "far fa-wallet",
    class: "has-sub",
    badge: "",
    badgeClass: "badge badge-pill badge-danger float-right mr-1 mt-1",
    isExternalLink: false,
    submenu: [
      {
        path: "/stock/employe-wallet",
        title: "محافظ الموظفين ",
        icon: "far fa-user-tag",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      },
      {
        path: "/stock/customer-wallet",
        title: "محافظ   العملاء ",
        icon: "far fa-user-shield",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      },

      // { path: '/setting/points', title: ' نقاط الموظفين ', icon: 'ft-bookmark', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      {
        path: "",
        title: "المحاسبة",
        icon: "far fa-receipt",
        class: "has-sub",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [
          {
            path: "/stock/archive-bank",
            title: " عمليات الشحن البنكي",
            icon: "far fa-money-check-edit-alt",
            class: "",
            badge: "",
            badgeClass: "",
            isExternalLink: false,
            submenu: [],
          },
          {
            path: "/stock/archive-cash",
            title: "  عمليات الشحن بالكاش ",
            icon: "far fa-money-check-edit",
            class: "",
            badge: "",
            badgeClass: "",
            isExternalLink: false,
            submenu: [],
          },

          {
            path: "/stock/transfer",
            title: " عمليات التحويل ",
            icon: "far fa-money-check",
            class: "",
            badge: "",
            badgeClass: "",
            isExternalLink: false,
            submenu: [],
          },
          
        ],
      },
    ],
  },

  {
    path: "",
    title: "إدارة المخزون",
    icon: "far fa-inventory",
    class: "has-sub",
    badge: "",
    badgeClass: "badge badge-pill badge-danger float-right mr-1 mt-1",
    isExternalLink: false,
    submenu: [
      {
        path: "/inventory/stock",
        title: "إستلام مخزون",
        icon: "far fa-dolly-flatbed",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      },
      {
        path: "/inventory/custody",
        title: " إستلام عهدة ",
        icon: "far fa-conveyor-belt",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      },
      {
        path: "/inventory/product",
        title: " المنتوج",
        icon: "far fa-shopping-cart",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      },
    ],
  },
  {
    path: "",
    title: "إعدادات النظام ",
    icon: "far fa-globe-africa",
    class: "has-sub",
    badge: "",
    badgeClass: "badge badge-pill badge-danger float-right mr-1 mt-1",
    isExternalLink: false,
    submenu: [
      {
        path: "/pays",
        title: "إدارة  الدول",
        icon: "far fa-city",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      },
      {
        path: "/countries",
        title: " إدارة  المناطق ",
        icon: "far fa-building",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      },

      {
        path: "/cities",
        title: " إدارة  المدن ",
        icon: "far fa-city",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      },

      {
        path: "/coins",
        title: " إدارة  العملات ",
        icon: "far fa-coins",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      },
      {
        path: "/bank",
        title: " إدارة  البنوك ",
        icon: "far fa-university ",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      },
    ],
  },

  {
    path: "",
    title: " إدارة  التسويق  ",
    icon: "far fa-bullhorn",
    class: "has-sub",
    badge: "",
    badgeClass: "badge badge-pill badge-danger float-right mr-1 mt-1",
    isExternalLink: false,
    submenu: [
      {
        path: "/sponsoring",
        title: "التسويق بالعمولة ",
        icon: "far fa-coin",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      },
      {
        path: "/sponsoring/list-marketing",
        title: "الحملات التسويقية",
        icon: "fab fa-hubspot",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      },
    ],
  },
  {
    path: "",
    title: "إدارة الموقع الإلكتروني",
    icon: "far fa-browser",
    class: "has-sub",
    badge: "",
    badgeClass: "badge badge-pill badge-danger float-right mr-1 mt-1",
    isExternalLink: false,
    submenu: [
      {
        path: "/socialmedia/about",
        title: "عن تكافل",
        icon: "far fa-address-card",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      },
      {
        path: "/socialmedia/social",
        title: "إدارة الشبكات الإجتماعية",
        icon: "far fa-hashtag",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      },

      {
        path: "/socialmedia/privacy",
        title: "   خصوصية  ",
        icon: "far fa-user-shield",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      },
    ],
  },
  {
    path: "",
    title: "إدارة الإعلانات و الأخبار",
    icon: "far fa-rss",
    class: "has-sub",
    badge: "",
    badgeClass: "badge badge-pill badge-danger float-right mr-1 mt-1",
    isExternalLink: false,
    submenu: [
      {
        path: "/ads",
        title: "إدارة الإعلانات",
        icon: "far fa-external-link-square",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      },
      {
        path: "/news",
        title: "إدارة الأخبار ",
        icon: "far fa-newspaper",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      },
    ],
  },
  {
    path: "",
    title: "إدارة الشكاوي و الإقتراحات ",
    icon: "far fa-folder-open",
    class: "has-sub",
    badge: "",
    badgeClass: "badge badge-pill badge-danger float-right mr-1 mt-1",
    isExternalLink: false,
    submenu: [
      {
        path: "/complains/complain-supplier",
        title: "الشكاوي و الإقتراحات ",
        icon: "far fa-file-alt",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      },
    ],
  },
  {
    path: "",
    title: "اعدادت التطبيق ",
    icon: "ft-settings",
    class: "has-sub",
    badge: "",
    badgeClass: "badge badge-pill badge-danger float-right mr-1 mt-1",
    isExternalLink: false,
    submenu: [
      // { path: '/setting', title: 'اعدادت التطبيق ', icon: 'far fa-cogs', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      {
        path: "/setting/terms",
        title: "الشروط و الإستخدام",
        icon: "far fa-box-check",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      },
      {
        path: "/setting/privacy",
        title: "سياسة الخصوصية",
        icon: "far fa-user-shield",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      },
      {
        path: "/setting/list-question",
        title: "الأسئلة الشائعة",
        icon: "far fa-question-circle",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      },
      {
        path: "/setting/about",
        title: "عن تكافل ",
        icon: "far fa-info-circle",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      },
      {
        path: "/setting/branchlist",
        title: "فروعنا ",
        icon: "fas fa-map-marker",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      },

      {
        path: "/setting/help",
        title: "مساعدة  ",
        icon: "far fa-people-carry",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      },
      {
        path: "/setting/other_page",
        title: "صفحات أخرى",
        icon: "far fa-page-break",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      },
      {
        path: "/setting/privileges",
        title: " إمتيازاتنا",
        icon: "far fa-badge-check",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      },
      {
        path: "/setting/points",
        title: " النقاط",
        icon: "far fa-map",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      },
      {
        path: "/setting/contact",
        title: "تواصل معنا  ",
        icon: "far fa-file-signature",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      },
    ],
  },
];

export  const ROUTES1: RouteInfo[] = [
    // {
    //     path: '', title: 'Dashboard', icon: 'ft-home', class: 'has-sub', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false, submenu: [
    //         { path: '/dashboard/dashboard1', title: 'Dashboard1', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    //     ]
    // },
   
    
   
    {
      path: "",
      title: "الكوبونات و العروض  ",
      icon: "far fa-gifts",
      class: "has-sub",
      badge: "",
      badgeClass: "badge badge-pill badge-danger float-right mr-1 mt-1",
      isExternalLink: false,
      submenu: [
        {
          path: "/coupons",
          title: " الكوبونات",
          icon: "far fa-gift",
          class: "",
          badge: "",
          badgeClass: "",
          isExternalLink: false,
          submenu: [],
        },
        {
          path: "/coupons/list-requests",
          title: " العروض ",
          icon: "far fa-file-search",
          class: "",
          badge: "",
          badgeClass: "",
          isExternalLink: false,
          submenu: [],
        },
      ],
    },
  
  ];
  
  export  const ROUTES2: RouteInfo[] = [
    // {
    //     path: '', title: 'Dashboard', icon: 'ft-home', class: 'has-sub', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false, submenu: [
    //         { path: '/dashboard/dashboard1', title: 'Dashboard1', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    //     ]
    // },
   
    {
        path: "",
        title: "إدارة المستخدمين ",
        icon: "far fa-poll-people",
        class: "has-sub",
        badge: "",
        badgeClass: "badge badge-pill badge-danger float-right mr-1 mt-1",
        isExternalLink: false,
        submenu: [
          {
            path: "/users",
            title: "إدارة الموظفين",
            icon: "far fa-user",
            class: "",
            badge: "",
            badgeClass: "",
            isExternalLink: false,
            submenu: [],
          },
          {
            path: "/jobs",
            title: "إدارة الوظائف",
            icon: "far fa-users-cog",
            class: "",
            badge: "",
            badgeClass: "",
            isExternalLink: false,
            submenu: [],
          },
          {
            path: "/missions",
            title: "إدارة الصلاحيات",
            icon: "far fa-user-lock",
            class: "",
            badge: "",
            badgeClass: "",
            isExternalLink: false,
            submenu: [],
          },
        ],
      },
   
    
      {
        path: "",
        title: "إدارة الطلبات ",
        icon: "far fa-game-board",
        class: "has-sub",
        badge: "",
        badgeClass: "badge badge-pill badge-danger float-right mr-1 mt-1",
        isExternalLink: false,
        submenu: [
          {
            path: "/cards/cardrequest",
            title: "الطلبات",
            icon: "far fa-address-card",
            class: "",
            badge: "",
            badgeClass: "",
            isExternalLink: false,
            submenu: [],
          },
         
          {
            path: "/cards",
            title: " البطاقات",
            icon: "far fa-credit-card-blank",
            class: "",
            badge: "",
            badgeClass: "",
            isExternalLink: false,
            submenu: [],
          },
          {
            path: "/cards/typecardlist",
            title: " أنواع البطاقات",
            icon: "far fa-credit-card-front",
            class: "",
            badge: "",
            badgeClass: "",
            isExternalLink: false,
            submenu: [],
          },
        ],
      },
    
      {
        path: "",
        title: "إدارة المحافظ",
        icon: "far fa-wallet",
        class: "has-sub",
        badge: "",
        badgeClass: "badge badge-pill badge-danger float-right mr-1 mt-1",
        isExternalLink: false,
        submenu: [
          {
            path: "/stock/my-ewallet",
            title: "محفظتي ",
            icon: "far fa-user-tag",
            class: "",
            badge: "",
            badgeClass: "",
            isExternalLink: false,
            submenu: [],
          },
          {
            path: "",
            title: "المحاسبة",
            icon: "far fa-receipt",
            class: "has-sub",
            badge: "",
            badgeClass: "",
            isExternalLink: false,
            submenu: [
              {
                path: "/stock/archive-bank",
                title: " عمليات الشحن البنكي",
                icon: "far fa-money-check-edit-alt",
                class: "",
                badge: "",
                badgeClass: "",
                isExternalLink: false,
                submenu: [],
              },
              {
                path: "/stock/archive-cash",
                title: "  عمليات الشحن بالكاش ",
                icon: "far fa-money-check-edit",
                class: "",
                badge: "",
                badgeClass: "",
                isExternalLink: false,
                submenu: [],
              },
    
              {
                path: "/stock/transfer",
                title: " عمليات التحويل ",
                icon: "far fa-money-check",
                class: "",
                badge: "",
                badgeClass: "",
                isExternalLink: false,
                submenu: [],
              },
              {
                path: "/stock/historique-sold",
                title: "أرشيف الأرصدة ",
                icon: "far fa-money-check",
                class: "",
                badge: "",
                badgeClass: "",
                isExternalLink: false,
                submenu: [],
              },
              
            ],
          },
     
    
          // { path: '/setting/points', title: ' نقاط الموظفين ', icon: 'ft-bookmark', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
          
        ],
      },
  
 
  ];
  export  const ROUTES3: RouteInfo[] = [
    // {
    //     path: '', title: 'Dashboard', icon: 'ft-home', class: 'has-sub', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false, submenu: [
    //         { path: '/dashboard/dashboard1', title: 'Dashboard1', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    //     ]
    // },
   
    {
      path: "",
      title: "إدارة المستخدمين ",
      icon: "far fa-poll-people",
      class: "has-sub",
      badge: "",
      badgeClass: "badge badge-pill badge-danger float-right mr-1 mt-1",
      isExternalLink: false,
      submenu: [
        {
          path: "/users",
          title: "إدارة الموظفين",
          icon: "far fa-user",
          class: "",
          badge: "",
          badgeClass: "",
          isExternalLink: false,
          submenu: [],
        },
        {
          path: "/jobs",
          title: "إدارة الوظائف",
          icon: "far fa-users-cog",
          class: "",
          badge: "",
          badgeClass: "",
          isExternalLink: false,
          submenu: [],
        },
        {
          path: "/missions",
          title: "إدارة الصلاحيات",
          icon: "far fa-user-lock",
          class: "",
          badge: "",
          badgeClass: "",
          isExternalLink: false,
          submenu: [],
        },
      ],
    },
   
    
    {
      path: "",
      title: "إدارة الطلبات ",
      icon: "far fa-game-board",
      class: "has-sub",
      badge: "",
      badgeClass: "badge badge-pill badge-danger float-right mr-1 mt-1",
      isExternalLink: false,
      submenu: [
        {
          path: "/cards/cardrequest",
          title: "الطلبات",
          icon: "far fa-address-card",
          class: "",
          badge: "",
          badgeClass: "",
          isExternalLink: false,
          submenu: [],
        },
        {
          path: "/cards/cancelledCard",
          title: " الطلبات المرفوضة",
          icon: "far fa-tasks",
          class: "",
          badge: "",
          badgeClass: "",
          isExternalLink: false,
          submenu: [],
        },
        {
          path: "/cards",
          title: " البطاقات",
          icon: "far fa-credit-card-blank",
          class: "",
          badge: "",
          badgeClass: "",
          isExternalLink: false,
          submenu: [],
        },
        {
          path: "/cards/typecardlist",
          title: " أنواع البطاقات",
          icon: "far fa-credit-card-front",
          class: "",
          badge: "",
          badgeClass: "",
          isExternalLink: false,
          submenu: [],
        },
      ],
    },
    
    {
      path: "",
      title: "إدارة المحافظ",
      icon: "far fa-wallet",
      class: "has-sub",
      badge: "",
      badgeClass: "badge badge-pill badge-danger float-right mr-1 mt-1",
      isExternalLink: false,
      submenu: [
        {
          path: "/stock/my-ewallet",
          title: "محفظتي ",
          icon: "far fa-user-tag",
          class: "",
          badge: "",
          badgeClass: "",
          isExternalLink: false,
          submenu: [],
        },
        {
          path: "",
          title: "المحاسبة",
          icon: "far fa-receipt",
          class: "has-sub",
          badge: "",
          badgeClass: "",
          isExternalLink: false,
          submenu: [
            {
              path: "/stock/archive-bank",
              title: " عمليات الشحن البنكي",
              icon: "far fa-money-check-edit-alt",
              class: "",
              badge: "",
              badgeClass: "",
              isExternalLink: false,
              submenu: [],
            },
            {
              path: "/stock/archive-cash",
              title: "  عمليات الشحن بالكاش ",
              icon: "far fa-money-check-edit",
              class: "",
              badge: "",
              badgeClass: "",
              isExternalLink: false,
              submenu: [],
            },
  
            {
              path: "/stock/transfer",
              title: " عمليات التحويل ",
              icon: "far fa-money-check",
              class: "",
              badge: "",
              badgeClass: "",
              isExternalLink: false,
              submenu: [],
            },
            {
              path: "/stock/detail-sold",
              title: "  طلب رصيد ",
              icon: "far fa-money-check",
              class: "",
              badge: "",
              badgeClass: "",
              isExternalLink: false,
              submenu: [],
            },
            {
              path: "/stock/historique-sold",
              title: "أرشيف الأرصدة ",
              icon: "far fa-money-check",
              class: "",
              badge: "",
              badgeClass: "",
              isExternalLink: false,
              submenu: [],
            },
            
          ],
        },
   
  
        // { path: '/setting/points', title: ' نقاط الموظفين ', icon: 'ft-bookmark', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        
      ],
    },
  
 
  ];
  export  const ROUTES4: RouteInfo[] = [
    // {
    //     path: '', title: 'Dashboard', icon: 'ft-home', class: 'has-sub', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false, submenu: [
    //         { path: '/dashboard/dashboard1', title: 'Dashboard1', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    //     ]
    // },
   
   
   
    
    {
      path: "",
      title: "إدارة الطلبات ",
      icon: "far fa-game-board",
      class: "has-sub",
      badge: "",
      badgeClass: "badge badge-pill badge-danger float-right mr-1 mt-1",
      isExternalLink: false,
      submenu: [
       
        {
          path: "/cards/cardrequest",
          title: "الطلبات",
          icon: "far fa-address-card",
          class: "",
          badge: "",
          badgeClass: "",
          isExternalLink: false,
          submenu: [],
        },
        {
          path: "/cards",
          title: " البطاقات",
          icon: "far fa-credit-card-blank",
          class: "",
          badge: "",
          badgeClass: "",
          isExternalLink: false,
          submenu: [],
        },
        
      ],
    },
    
    {
      path: "",
      title: "إدارة المحافظ",
      icon: "far fa-wallet",
      class: "has-sub",
      badge: "",
      badgeClass: "badge badge-pill badge-danger float-right mr-1 mt-1",
      isExternalLink: false,
      submenu: [
        {
          path: "/stock/my-ewallet",
          title: "محفظتي ",
          icon: "far fa-user-tag",
          class: "",
          badge: "",
          badgeClass: "",
          isExternalLink: false,
          submenu: [],
        },
        {
          path: "",
          title: "المحاسبة",
          icon: "far fa-receipt",
          class: "has-sub",
          badge: "",
          badgeClass: "",
          isExternalLink: false,
          submenu: [
            {
              path: "/stock/archive-bank",
              title: " عمليات الشحن البنكي",
              icon: "far fa-money-check-edit-alt",
              class: "",
              badge: "",
              badgeClass: "",
              isExternalLink: false,
              submenu: [],
            },
            {
              path: "/stock/archive-cash",
              title: "  عمليات الشحن بالكاش ",
              icon: "far fa-money-check-edit",
              class: "",
              badge: "",
              badgeClass: "",
              isExternalLink: false,
              submenu: [],
            },
  
            {
              path: "/stock/transfer",
              title: " عمليات التحويل ",
              icon: "far fa-money-check",
              class: "",
              badge: "",
              badgeClass: "",
              isExternalLink: false,
              submenu: [],
            },
            {
              path: "/stock/detail-sold",
              title: "  طلب رصيد ",
              icon: "far fa-money-check",
              class: "",
              badge: "",
              badgeClass: "",
              isExternalLink: false,
              submenu: [],
            },
            {
              path: "/stock/historique-sold",
              title: "أرشيف الأرصدة ",
              icon: "far fa-money-check",
              class: "",
              badge: "",
              badgeClass: "",
              isExternalLink: false,
              submenu: [],
            },
            
          ],
        },
   
  
        // { path: '/setting/points', title: ' نقاط الموظفين ', icon: 'ft-bookmark', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        
      ],
    },
    
  
 
  ];
  export  const ROUTES5: RouteInfo[] = [
    // {
    //     path: '', title: 'Dashboard', icon: 'ft-home', class: 'has-sub', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false, submenu: [
    //         { path: '/dashboard/dashboard1', title: 'Dashboard1', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    //     ]
    // },
   
   
    {
      path: "/cards/hitorique",
      title: "أرشيف الطلبات ",
      icon: "far fa-user-tag",
      class: "",
      badge: "",
      badgeClass: "badge badge-pill badge-danger float-right mr-1 mt-1",
      isExternalLink: false,
      submenu: [],
    },
    
    {
        path: "",
        title: "إدارة المحافظ",
        icon: "far fa-wallet",
        class: "has-sub",
        badge: "",
        badgeClass: "badge badge-pill badge-danger float-right mr-1 mt-1",
        isExternalLink: false,
        submenu: [
          {
            path: "/stock/employe-wallet",
            title: "محافظ الموظفين ",
            icon: "far fa-user-tag",
            class: "",
            badge: "",
            badgeClass: "",
            isExternalLink: false,
            submenu: [],
          },
          {
            path: "/stock/customer-wallet",
            title: "محافظ   العملاء ",
            icon: "far fa-user-shield",
            class: "",
            badge: "",
            badgeClass: "",
            isExternalLink: false,
            submenu: [],
          },
    
          // { path: '/setting/points', title: ' نقاط الموظفين ', icon: 'ft-bookmark', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
          {
            path: "",
            title: "المحاسبة",
            icon: "far fa-receipt",
            class: "has-sub",
            badge: "",
            badgeClass: "",
            isExternalLink: false,
            submenu: [
              {
                path: "/stock/archive-bank",
                title: " عمليات الشحن البنكي",
                icon: "far fa-money-check-edit-alt",
                class: "",
                badge: "",
                badgeClass: "",
                isExternalLink: false,
                submenu: [],
              },
              {
                path: "/stock/archive-cash",
                title: "  عمليات الشحن بالكاش ",
                icon: "far fa-money-check-edit",
                class: "",
                badge: "",
                badgeClass: "",
                isExternalLink: false,
                submenu: [],
              },
    
              {
                path: "/stock/transfer",
                title: " عمليات التحويل ",
                icon: "far fa-money-check",
                class: "",
                badge: "",
                badgeClass: "",
                isExternalLink: false,
                submenu: [],
              },
              {
                path: "/stock/detail-sold",
                title: "  طلب رصيد ",
                icon: "far fa-money-check",
                class: "",
                badge: "",
                badgeClass: "",
                isExternalLink: false,
                submenu: [],
              },
              {
                path: "/stock/historique-sold",
                title: "أرشيف الأرصدة ",
                icon: "far fa-money-check",
                class: "",
                badge: "",
                badgeClass: "",
                isExternalLink: false,
                submenu: [],
              },
            ],
          },
        ],
      },
  
 
  ];
  export  const ROUTES6: RouteInfo[] = [
    // {
    //     path: '', title: 'Dashboard', icon: 'ft-home', class: 'has-sub', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false, submenu: [
    //         { path: '/dashboard/dashboard1', title: 'Dashboard1', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    //     ]
    // },
   
    {
        path: "",
        title: "إدارة مقدمي الخدمة ",
        icon: "far fa-briefcase",
        class: "has-sub",
        badge: "",
        badgeClass: "badge badge-pill badge-danger float-right mr-1 mt-1",
        isExternalLink: false,
        submenu: [
          {
            path: "/serviceprovider",
            title: "إدارة المراكز الطبية ",
            icon: "far fa-folder-tree",
            class: "",
            badge: "",
            badgeClass: "",
            isExternalLink: false,
            submenu: [],
          },
          {
            path: "/serviceprovider/new-branch",
            title: " إنشاء فرع ",
            icon: "far fa-folder-tree",
            class: "",
            badge: "",
            badgeClass: "",
            isExternalLink: false,
            submenu: [],
          },
    
          {
            path: "/service",
            title: "خدمات المراكز ",
            icon: "far fa-clipboard-list",
            class: "",
            badge: "",
            badgeClass: "",
            isExternalLink: false,
            submenu: [],
          },
          {
            path: "/service/allServ",
            title: " الخدمات ",
            icon: "far fa-clipboard-list-check",
            class: "",
            badge: "",
            badgeClass: "",
            isExternalLink: false,
            submenu: [],
          },
          {
            path: "/contracts",
            title: " العقود ",
            icon: "far fa-file-contract",
            class: "",
            badge: "",
            badgeClass: "",
            isExternalLink: false,
            submenu: [],
          },
        ],
      },
    
      {
        path: "",
        title: "إدارة المحافظ",
        icon: "far fa-wallet",
        class: "has-sub",
        badge: "",
        badgeClass: "badge badge-pill badge-danger float-right mr-1 mt-1",
        isExternalLink: false,
        submenu: [
          {
            path: "/stock/my-ewallet",
            title: "محفظتي ",
            icon: "far fa-user-tag",
            class: "",
            badge: "",
            badgeClass: "",
            isExternalLink: false,
            submenu: [],
          },
     
    
          // { path: '/setting/points', title: ' نقاط الموظفين ', icon: 'ft-bookmark', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
          
        ],
      },
 
  ];
   export  const ROUTES7: RouteInfo[] = [
    // {
    //     path: '', title: 'Dashboard', icon: 'ft-home', class: 'has-sub', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false, submenu: [
    //         { path: '/dashboard/dashboard1', title: 'Dashboard1', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    //     ]
    // },
   
    {
      path: "",
      title: "إدارة الطلبات ",
      icon: "far fa-game-board",
      class: "has-sub",
      badge: "",
      badgeClass: "badge badge-pill badge-danger float-right mr-1 mt-1",
      isExternalLink: false,
      submenu: [
        {
          path: "/cards/cardrequest",
          title: "الطلبات",
          icon: "far fa-address-card",
          class: "",
          badge: "",
          badgeClass: "",
          isExternalLink: false,
          submenu: [],
        },
        {
          path: "/cards/DelivredCard",
          title: " الطلبات المسلمة للسائق ",
          icon: "far fa-tasks",
          class: "",
          badge: "",
          badgeClass: "",
          isExternalLink: false,
          submenu: [],
        },
        // {
        //   path: "/cards",
        //   title: " البطاقات",
        //   icon: "far fa-credit-card-blank",
        //   class: "",
        //   badge: "",
        //   badgeClass: "",
        //   isExternalLink: false,
        //   submenu: [],
        // },
        // {
        //   path: "/cards/typecardlist",
        //   title: " أنواع البطاقات",
        //   icon: "far fa-credit-card-front",
        //   class: "",
        //   badge: "",
        //   badgeClass: "",
        //   isExternalLink: false,
        //   submenu: [],
        // },
      ],
    },
    {
      path: "",
      title: "إدارة المحافظ",
      icon: "far fa-wallet",
      class: "has-sub",
      badge: "",
      badgeClass: "badge badge-pill badge-danger float-right mr-1 mt-1",
      isExternalLink: false,
      submenu: [
        {
          path: "/stock/my-ewallet",
          title: "محفظتي ",
          icon: "far fa-user-tag",
          class: "",
          badge: "",
          badgeClass: "",
          isExternalLink: false,
          submenu: [],
        },
        {
          path: "/stock/detail-sold",
          title: "  طلب رصيد ",
          icon: "far fa-money-check",
          class: "",
          badge: "",
          badgeClass: "",
          isExternalLink: false,
          submenu: [],
        },
        {
          path: "/stock/historique-sold",
          title: "أرشيف الأرصدة ",
          icon: "far fa-money-check",
          class: "",
          badge: "",
          badgeClass: "",
          isExternalLink: false,
          submenu: [],
        },
  
        // { path: '/setting/points', title: ' نقاط الموظفين ', icon: 'ft-bookmark', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        
      ],
    },
  
 
  ];
  export  const ROUTES8: RouteInfo[] = [
    // {
    //     path: '', title: 'Dashboard', icon: 'ft-home', class: 'has-sub', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false, submenu: [
    //         { path: '/dashboard/dashboard1', title: 'Dashboard1', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    //     ]
    // },
    {
      path: "/management",
      title: "الرئيسية ",
      icon: "far fa-th-large",
      class: "",
      badge: "",
      badgeClass: "badge badge-pill badge-danger float-right mr-1 mt-1",
      isExternalLink: false,
      submenu: [],
    },
    {
      path: "",
      title: "إدارة المستخدمين ",
      icon: "far fa-poll-people",
      class: "has-sub",
      badge: "",
      badgeClass: "badge badge-pill badge-danger float-right mr-1 mt-1",
      isExternalLink: false,
      submenu: [
        {
          path: "/users",
          title: "إدارة الموظفين",
          icon: "far fa-user",
          class: "",
          badge: "",
          badgeClass: "",
          isExternalLink: false,
          submenu: [],
        },
        {
          path: "/jobs",
          title: "إدارة الوظائف",
          icon: "far fa-users-cog",
          class: "",
          badge: "",
          badgeClass: "",
          isExternalLink: false,
          submenu: [],
        },
        {
          path: "/missions",
          title: "إدارة الصلاحيات",
          icon: "far fa-user-lock",
          class: "",
          badge: "",
          badgeClass: "",
          isExternalLink: false,
          submenu: [],
        },
      ],
    },
    {
      path: "",
      title: "إدارة مقدمي الخدمة ",
      icon: "far fa-briefcase",
      class: "has-sub",
      badge: "",
      badgeClass: "badge badge-pill badge-danger float-right mr-1 mt-1",
      isExternalLink: false,
      submenu: [
        {
          path: "/serviceprovider",
          title: "إدارة المراكز الطبية ",
          icon: "far fa-folder-tree",
          class: "",
          badge: "",
          badgeClass: "",
          isExternalLink: false,
          submenu: [],
        },
        {
          path: "/serviceprovider/new-branch",
          title: " إنشاء فرع ",
          icon: "far fa-folder-tree",
          class: "",
          badge: "",
          badgeClass: "",
          isExternalLink: false,
          submenu: [],
        },
  
        {
          path: "/service",
          title: "خدمات المراكز ",
          icon: "far fa-clipboard-list",
          class: "",
          badge: "",
          badgeClass: "",
          isExternalLink: false,
          submenu: [],
        },
        {
          path: "/service/allServ",
          title: "الخدمات",
          icon: "far fa-clipboard-list-check",
          class: "",
          badge: "",
          badgeClass: "",
          isExternalLink: false,
          submenu: [],
        },
        {
          path: "/contracts",
          title: " العقود ",
          icon: "far fa-file-contract",
          class: "",
          badge: "",
          badgeClass: "",
          isExternalLink: false,
          submenu: [],
        },
      ],
    },
    {
      path: "",
      title: "إدارة العملاء ",
      icon: "far fa-users-class",
      class: "has-sub",
      badge: "",
      badgeClass: "badge badge-pill badge-danger float-right mr-1 mt-1",
      isExternalLink: false,
      submenu: [
        {
          path: "/client",
          title: " العملاء",
          icon: "far fa-users-crown",
          class: "",
          badge: "",
          badgeClass: "",
          isExternalLink: false,
          submenu: [],
        },
        {
          path: "/client/membreship",
          title: "التحقق من العضوية",
          icon: "far fa-user-check",
          class: "",
          badge: "",
          badgeClass: "",
          isExternalLink: false,
          submenu: [],
        },
  
        {
          path: "/serviceprovider/suggestion",
          title: "الإنضمام أو إقتراح مركز",
          icon: "far fa-university",
          class: "",
          badge: "",
          badgeClass: "",
          isExternalLink: false,
          submenu: [],
        },
  
        {
          path: "",
          title: "إدارة التوظيف",
          icon: "far fa-user-tie",
          class: "has-sub",
          badge: "",
          badgeClass: "",
          isExternalLink: false,
          submenu: [
            {
              path: "/employment/candidacy",
              title: "مطالب الترشح",
              icon: "far fa-users-cog",
              class: "",
              badge: "",
              badgeClass: "",
              isExternalLink: false,
              submenu: [],
            },
            // { path: '/employment/demand', title: 'مطلب انظمام كتاجر', icon: 'far fa-book-user', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
          ],
        },
      ],
    },
    {
      path: "",
      title: "إدارة الطلبات ",
      icon: "far fa-game-board",
      class: "has-sub",
      badge: "",
      badgeClass: "badge badge-pill badge-danger float-right mr-1 mt-1",
      isExternalLink: false,
      submenu: [
        {
          path: "/cards/cardrequest",
          title: "الطلبات",
          icon: "far fa-address-card",
          class: "",
          badge: "",
          badgeClass: "",
          isExternalLink: false,
          submenu: [],
        },
        {
          path: "/cards/cancelledCard",
          title: " الطلبات المرفوضة",
          icon: "far fa-tasks",
          class: "",
          badge: "",
          badgeClass: "",
          isExternalLink: false,
          submenu: [],
        },
        {
          path: "/cards",
          title: " البطاقات",
          icon: "far fa-credit-card-blank",
          class: "",
          badge: "",
          badgeClass: "",
          isExternalLink: false,
          submenu: [],
        },
        {
          path: "/cards/typecardlist",
          title: " أنواع البطاقات",
          icon: "far fa-credit-card-front",
          class: "",
          badge: "",
          badgeClass: "",
          isExternalLink: false,
          submenu: [],
        },
      ],
    },
    {
      path: "",
      title: "الكوبونات و العروض  ",
      icon: "far fa-gifts",
      class: "has-sub",
      badge: "",
      badgeClass: "badge badge-pill badge-danger float-right mr-1 mt-1",
      isExternalLink: false,
      submenu: [
        {
          path: "/coupons",
          title: " الكوبونات",
          icon: "far fa-gift",
          class: "",
          badge: "",
          badgeClass: "",
          isExternalLink: false,
          submenu: [],
        },
        {
          path: "/coupons/list-requests",
          title: " العروض ",
          icon: "far fa-file-search",
          class: "",
          badge: "",
          badgeClass: "",
          isExternalLink: false,
          submenu: [],
        },
      ],
    },
    {
      path: "",
      title: "إدارة المحافظ",
      icon: "far fa-wallet",
      class: "has-sub",
      badge: "",
      badgeClass: "badge badge-pill badge-danger float-right mr-1 mt-1",
      isExternalLink: false,
      submenu: [
        {
          path: "/stock/employe-wallet",
          title: "محافظ الموظفين ",
          icon: "far fa-user-tag",
          class: "",
          badge: "",
          badgeClass: "",
          isExternalLink: false,
          submenu: [],
        },
        {
          path: "/stock/customer-wallet",
          title: "محافظ   العملاء ",
          icon: "far fa-user-shield",
          class: "",
          badge: "",
          badgeClass: "",
          isExternalLink: false,
          submenu: [],
        },
  
        // { path: '/setting/points', title: ' نقاط الموظفين ', icon: 'ft-bookmark', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        {
          path: "",
          title: "المحاسبة",
          icon: "far fa-receipt",
          class: "has-sub",
          badge: "",
          badgeClass: "",
          isExternalLink: false,
          submenu: [
            {
              path: "/stock/archive-bank",
              title: " عمليات الشحن البنكي",
              icon: "far fa-money-check-edit-alt",
              class: "",
              badge: "",
              badgeClass: "",
              isExternalLink: false,
              submenu: [],
            },
            {
              path: "/stock/archive-cash",
              title: "  عمليات الشحن بالكاش ",
              icon: "far fa-money-check-edit",
              class: "",
              badge: "",
              badgeClass: "",
              isExternalLink: false,
              submenu: [],
            },
  
            {
              path: "/stock/transfer",
              title: " عمليات التحويل ",
              icon: "far fa-money-check",
              class: "",
              badge: "",
              badgeClass: "",
              isExternalLink: false,
              submenu: [],
            },
            
          ],
        },
      ],
    },
  
    {
      path: "",
      title: "إدارة المخزون",
      icon: "far fa-inventory",
      class: "has-sub",
      badge: "",
      badgeClass: "badge badge-pill badge-danger float-right mr-1 mt-1",
      isExternalLink: false,
      submenu: [
        {
          path: "/inventory/stock",
          title: "إستلام مخزون",
          icon: "far fa-dolly-flatbed",
          class: "",
          badge: "",
          badgeClass: "",
          isExternalLink: false,
          submenu: [],
        },
        {
          path: "/inventory/custody",
          title: " إستلام عهدة ",
          icon: "far fa-conveyor-belt",
          class: "",
          badge: "",
          badgeClass: "",
          isExternalLink: false,
          submenu: [],
        },
        {
          path: "/inventory/product",
          title: " المنتوج",
          icon: "far fa-shopping-cart",
          class: "",
          badge: "",
          badgeClass: "",
          isExternalLink: false,
          submenu: [],
        },
      ],
    },
    {
      path: "",
      title: "إعدادات النظام ",
      icon: "far fa-globe-africa",
      class: "has-sub",
      badge: "",
      badgeClass: "badge badge-pill badge-danger float-right mr-1 mt-1",
      isExternalLink: false,
      submenu: [
        {
          path: "/pays",
          title: "إدارة  الدول",
          icon: "far fa-city",
          class: "",
          badge: "",
          badgeClass: "",
          isExternalLink: false,
          submenu: [],
        },
        {
          path: "/countries",
          title: " إدارة  المناطق ",
          icon: "far fa-building",
          class: "",
          badge: "",
          badgeClass: "",
          isExternalLink: false,
          submenu: [],
        },
  
        {
          path: "/cities",
          title: " إدارة  المدن ",
          icon: "far fa-city",
          class: "",
          badge: "",
          badgeClass: "",
          isExternalLink: false,
          submenu: [],
        },
  
        {
          path: "/coins",
          title: " إدارة  العملات ",
          icon: "far fa-coins",
          class: "",
          badge: "",
          badgeClass: "",
          isExternalLink: false,
          submenu: [],
        },
        {
          path: "/bank",
          title: " إدارة  البنوك ",
          icon: "far fa-university ",
          class: "",
          badge: "",
          badgeClass: "",
          isExternalLink: false,
          submenu: [],
        },
      ],
    },
  
    {
      path: "",
      title: " إدارة  التسويق  ",
      icon: "far fa-bullhorn",
      class: "has-sub",
      badge: "",
      badgeClass: "badge badge-pill badge-danger float-right mr-1 mt-1",
      isExternalLink: false,
      submenu: [
        {
          path: "/sponsoring",
          title: "التسويق بالعمولة ",
          icon: "far fa-coin",
          class: "",
          badge: "",
          badgeClass: "",
          isExternalLink: false,
          submenu: [],
        },
        {
          path: "/sponsoring/list-marketing",
          title: "الحملات التسويقية",
          icon: "fab fa-hubspot",
          class: "",
          badge: "",
          badgeClass: "",
          isExternalLink: false,
          submenu: [],
        },
      ],
    },
    {
      path: "",
      title: "إدارة الموقع الإلكتروني",
      icon: "far fa-browser",
      class: "has-sub",
      badge: "",
      badgeClass: "badge badge-pill badge-danger float-right mr-1 mt-1",
      isExternalLink: false,
      submenu: [
        {
          path: "/socialmedia/about",
          title: "عن تكافل",
          icon: "far fa-address-card",
          class: "",
          badge: "",
          badgeClass: "",
          isExternalLink: false,
          submenu: [],
        },
        {
          path: "/socialmedia/social",
          title: "إدارة الشبكات الإجتماعية",
          icon: "far fa-hashtag",
          class: "",
          badge: "",
          badgeClass: "",
          isExternalLink: false,
          submenu: [],
        },
  
        {
          path: "/socialmedia/privacy",
          title: "   خصوصية  ",
          icon: "far fa-user-shield",
          class: "",
          badge: "",
          badgeClass: "",
          isExternalLink: false,
          submenu: [],
        },
      ],
    },
    {
      path: "",
      title: "إدارة الإعلانات و الأخبار",
      icon: "far fa-rss",
      class: "has-sub",
      badge: "",
      badgeClass: "badge badge-pill badge-danger float-right mr-1 mt-1",
      isExternalLink: false,
      submenu: [
        {
          path: "/ads",
          title: "إدارة الإعلانات",
          icon: "far fa-external-link-square",
          class: "",
          badge: "",
          badgeClass: "",
          isExternalLink: false,
          submenu: [],
        },
        {
          path: "/news",
          title: "إدارة الأخبار ",
          icon: "far fa-newspaper",
          class: "",
          badge: "",
          badgeClass: "",
          isExternalLink: false,
          submenu: [],
        },
      ],
    },
    {
      path: "",
      title: "إدارة الشكاوي و الإقتراحات ",
      icon: "far fa-folder-open",
      class: "has-sub",
      badge: "",
      badgeClass: "badge badge-pill badge-danger float-right mr-1 mt-1",
      isExternalLink: false,
      submenu: [
        {
          path: "/complains/complain-supplier",
          title: "الشكاوي و الإقتراحات ",
          icon: "far fa-file-alt",
          class: "",
          badge: "",
          badgeClass: "",
          isExternalLink: false,
          submenu: [],
        },
      ],
    },
    {
      path: "",
      title: "اعدادت التطبيق ",
      icon: "ft-settings",
      class: "has-sub",
      badge: "",
      badgeClass: "badge badge-pill badge-danger float-right mr-1 mt-1",
      isExternalLink: false,
      submenu: [
        // { path: '/setting', title: 'اعدادت التطبيق ', icon: 'far fa-cogs', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        {
          path: "/setting/terms",
          title: "الشروط و الإستخدام",
          icon: "far fa-box-check",
          class: "",
          badge: "",
          badgeClass: "",
          isExternalLink: false,
          submenu: [],
        },
        {
          path: "/setting/privacy",
          title: "سياسة الخصوصية",
          icon: "far fa-user-shield",
          class: "",
          badge: "",
          badgeClass: "",
          isExternalLink: false,
          submenu: [],
        },
        {
          path: "/setting/list-question",
          title: "الأسئلة الشائعة",
          icon: "far fa-question-circle",
          class: "",
          badge: "",
          badgeClass: "",
          isExternalLink: false,
          submenu: [],
        },
        {
          path: "/setting/about",
          title: "عن تكافل ",
          icon: "far fa-info-circle",
          class: "",
          badge: "",
          badgeClass: "",
          isExternalLink: false,
          submenu: [],
        },
        {
          path: "/setting/branchlist",
          title: "فروعنا ",
          icon: "fas fa-map-marker",
          class: "",
          badge: "",
          badgeClass: "",
          isExternalLink: false,
          submenu: [],
        },
  
        {
          path: "/setting/help",
          title: "مساعدة  ",
          icon: "far fa-people-carry",
          class: "",
          badge: "",
          badgeClass: "",
          isExternalLink: false,
          submenu: [],
        },
        {
          path: "/setting/other_page",
          title: "صفحات أخرى",
          icon: "far fa-page-break",
          class: "",
          badge: "",
          badgeClass: "",
          isExternalLink: false,
          submenu: [],
        },
        {
          path: "/setting/privileges",
          title: " إمتيازاتنا",
          icon: "far fa-badge-check",
          class: "",
          badge: "",
          badgeClass: "",
          isExternalLink: false,
          submenu: [],
        },
        {
          path: "/setting/points",
          title: " النقاط",
          icon: "far fa-map",
          class: "",
          badge: "",
          badgeClass: "",
          isExternalLink: false,
          submenu: [],
        },
        {
          path: "/setting/contact",
          title: "تواصل معنا  ",
          icon: "far fa-file-signature",
          class: "",
          badge: "",
          badgeClass: "",
          isExternalLink: false,
          submenu: [],
        },
      ],
    },
  ];
  export  const ROUTES9: RouteInfo[] = [
    
   
    {
      path: "",
      title: "إدارة الطلبات ",
      icon: "far fa-game-board",
      class: "has-sub",
      badge: "",
      badgeClass: "badge badge-pill badge-danger float-right mr-1 mt-1",
      isExternalLink: false,
      submenu: [
        {
          path: "/cards/cardrequest",
          title: "الطلبات",
          icon: "far fa-address-card",
          class: "",
          badge: "",
          badgeClass: "",
          isExternalLink: false,
          submenu: [],
        },
        // {
        //   path: "/cards/DelivredCard",
        //   title: " الطلبات المسلمة للسائق ",
        //   icon: "far fa-tasks",
        //   class: "",
        //   badge: "",
        //   badgeClass: "",
        //   isExternalLink: false,
        //   submenu: [],
        // },
        
      ],
    },
    {
      path: "",
      title: "إدارة المحافظ",
      icon: "far fa-wallet",
      class: "has-sub",
      badge: "",
      badgeClass: "badge badge-pill badge-danger float-right mr-1 mt-1",
      isExternalLink: false,
      submenu: [
        {
          path: "/stock/my-ewallet",
          title: "محفظتي ",
          icon: "far fa-user-tag",
          class: "",
          badge: "",
          badgeClass: "",
          isExternalLink: false,
          submenu: [],
        },
        {
          path: "/stock/detail-sold",
          title: "  طلب رصيد ",
          icon: "far fa-money-check",
          class: "",
          badge: "",
          badgeClass: "",
          isExternalLink: false,
          submenu: [],
        },
        {
          path: "/stock/historique-sold",
          title: "أرشيف الأرصدة ",
          icon: "far fa-money-check",
          class: "",
          badge: "",
          badgeClass: "",
          isExternalLink: false,
          submenu: [],
        },
        
      ],
    },
  
 
  ];