import { Router } from "@angular/router";
import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from "@angular/common/http";

import { AuthentificationService } from "app/services/authentification.service";
import { BehaviorSubject, Observable } from "rxjs/Rx";
import {
  catchError,
  filter,
  map,
  switchMap,
  take,
} from "rxjs/internal/operators";
import { throwError } from "rxjs/index";

@Injectable({ providedIn: "root" })
export class AuthInterceptor implements HttpInterceptor {
  private JWT_TOKEN = "JWT_TOKEN";
  private REFRESH_TOKEN = "REFRESH_TOKEN";

  private TOKEN_HEADER_KEY = "Authorization";
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );

  constructor(
    private authserice: AuthentificationService,
    private router: Router
  ) {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler): Observable<HttpEvent<Object>> 
    {
    let authReq = req;
    const token = localStorage.getItem(this.JWT_TOKEN);
    if (token != null) {
      authReq = this.addTokenHeader(req, token);
    }

    return next.handle(authReq).pipe(
      catchError((error) => {
        if (
          error instanceof HttpErrorResponse &&
          !authReq.url.includes("pages/login") &&
          error.status === 401
        ) 
        {
          return this.handle401Error(authReq, next);
        }

        return throwError(error);
      })
    );
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      const token = localStorage.getItem(this.JWT_TOKEN);

      if (token)
        return this.authserice.refreshToken().pipe(
          switchMap((res: any) => {
            this.isRefreshing = false;

            console.log("okkk refresh token ::::: ", res.data.token);

            // this.tokenService.saveToken(token.accessToken);
            localStorage.setItem(this.JWT_TOKEN, res.data.token);
            localStorage.setItem(this.REFRESH_TOKEN, res.data.refresh_token);

            this.refreshTokenSubject.next(res.data.token);

            return next.handle(this.addTokenHeader(request, res.data.token));
          }),
          catchError((err) => {
            this.isRefreshing = false;

            this.router.navigate(["/pages/login"]);
            return throwError(err);
          })
        );
    }

    return this.refreshTokenSubject.pipe(
      filter((token) => token !== null),
      take(1),
      switchMap((token) => next.handle(this.addTokenHeader(request, token)))
    );
  }

  private addTokenHeader(request: HttpRequest<any>, token: string) {
    /* for Spring Boot back-end */
    // return request.clone({ headers: request.headers.set(TOKEN_HEADER_KEY, 'Bearer ' + token) });

    /* for Node.js Express back-end */
    return request.clone({
      headers: request.headers.set(this.TOKEN_HEADER_KEY, token),
    });
  }
}
