import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/internal/operators';
import {Config} from "../../environments/config";

@Injectable({
  providedIn: 'root'
})

export class AdminService {
  admin_service_endpoint = Config.serverUrl+'/users/';
  chck_service_endpoint = Config.serverUrl+'/admins/account-check';
  recover_service_endpoint = Config.serverUrl+'/users/password/recovering/';
  constructor(private httpClient: HttpClient) {
  }

  /**
   * Get the list of admin.
   * @return {Observable<Admin>} List placeholder.
   */
  getall_admin(p): Observable<any> {

    return this.httpClient.get<any>(this.admin_service_endpoint + p +'/20').pipe(
      map((res: any) => {

        if (res.status === 200) {
          const payload = res.data;
          return payload;

        }

      })
    );
  }

  /**
   *check email if exist.
   * @return {Observable<DataObject>} Object placeholder.
   */
  check_email(email: string): Observable<any> {
    console.log(email)
    return this.httpClient.post(this.chck_service_endpoint,{email:email}).pipe(
      map((res: any) => {

        if (res.status === 200) {
          const payload = res.data;
          return payload;

        }
      }));
  }
  /**
   *updatestate.
   * @return {Observable<any>} Object placeholder.
   */
  update_state(id: string): Observable<any> {
    return this.httpClient.post(this.admin_service_endpoint+'account-status/'+id,{}).pipe(
      map((res: any) => {

        if (res.status === 200) {
          const payload = res.data;
          return payload;

        }
      }));
  }
  /**
   *recover password.
   * @return message.
   */
  send_email_recover(email: string): Observable<any> {
    console.log(email)
    return this.httpClient.post(this.recover_service_endpoint+'send-email',{email:email}).pipe(
      map((res: any) => {
          return res;
      }));
  }
  /**
   *verification email.
   * @return message.
   */
  verification_code_recover(data:any): Observable<any> {
    return this.httpClient.post(this.recover_service_endpoint+'code-verification',data).pipe(
      map((res: any) => {
          return res;
      }));
  }
  /**
   *change password.
   * @return message.
   */
  change_password(context: any): Observable<any> {
    const dataRequest = {
      email:context.email,
      password :context.password
    }
    console.log("okkkk dataRequest ",dataRequest)
    return this.httpClient.post(this.recover_service_endpoint,dataRequest).pipe(
      map((res: any) => {
        console.log("okkk res",res)
          return res;

      }));
  }
  /**
   * Add New Admin.
   * @return {Observable<DataObject>} Object placeholder.
   */
  add_newadmin(data): Observable<any> {
    return this.httpClient.post(this.admin_service_endpoint+'admins', data).pipe(
      map((res: any) => {
        if (res) {
          console.log(res);
          return res;
        }
      }));
  }
  getall_status(): Observable<any> {
    return this.httpClient.post<any>(this.admin_service_endpoint+'statistic',{}).pipe(
      map((res: any) => {
          return res['data'];
      })
    );
  }
  getProfile(): Observable<any> {
    return this.httpClient.get<any>(this.admin_service_endpoint+'profile/ar').pipe(
      map((res: any) => {
          return res['data'];
      })
    );
  }
  update_user( data): Observable<any> {
    return this.httpClient.put(this.admin_service_endpoint , data).pipe(
      map((res: any) => {
        console.log("okkkk", res);

        console.log(res);
        return res;
        
      })
    );
  }
  changer_password(data): Observable<any> {
    return this.httpClient.post(this.admin_service_endpoint +'updatePassword', data).pipe(
      map((res: any) => {
        console.log("okkkk", res);

        console.log(res);
        return res;
        
      })
    );
  }
}
