import { environment } from "./../../environments/environment.prod";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AngularFireMessaging } from "@angular/fire/messaging";
import { mergeMapTo } from "rxjs/operators";
import { take } from "rxjs/operators";
import { BehaviorSubject } from "rxjs";
import { Config } from 'environments/config';
import { Observable } from 'rxjs';
import { map } from 'rxjs/internal/operators';
@Injectable({
  providedIn: "root",
})
export class NotificationsService {
  currentMessage = new BehaviorSubject(null);
  private Endpoint_fcm = environment.baseUrl + "/users/fcm_to_mobile";
  notification_service_endpoint = Config.serverUrl+'/notifications/admin/';
  notification_service_endpoint_count = Config.serverUrl+'/notifications/count/';
  notification_update_service_endpoint_count = Config.serverUrl+'/notifications/';


  constructor(
    private angularFireMessaging: AngularFireMessaging,
    private httpClient: HttpClient
  ) {
    this.angularFireMessaging.messages.subscribe(
      (_messaging:any) => {
        console.log("notificationss")
        _messaging.onMessage = _messaging.onMessage.bind(_messaging);
        _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
      }
    );
  }
  get_all_notif(p,per): Observable<any> {
    return this.httpClient.get<any>(this.notification_service_endpoint+'ar/'+p+"/"+per).pipe(
      map((res: any) => {
        if (res.status == 200) {
          // console.log(res);
          return res['data'];
        }
      })
    );
  }
  get_all_notif_user(p,per): Observable<any> {
    return this.httpClient.get<any>(this.notification_update_service_endpoint_count+'mobile/ar/'+p).pipe(
      map((res: any) => {
        if (res.status == 200) {
          // console.log(res);
          return res['data'];
        }
      })
    );
  }
  
  get_all_notif_no_pagination(p,per): Observable<any> {
    return this.httpClient.get<any>(this.notification_service_endpoint+'ar/'+p+"/"+per).pipe(
      map((res: any) => {
        if (res.status == 200) {
          // console.log(res);
          return res['data'];
        }
      })
    );
  }

  get_count(): Observable<any> {
    return this.httpClient.get<any>(this.notification_service_endpoint + 'count').pipe(
      map((res: any) => {
        if (res.status == 200) {
          // console.log(res);
          return res['data'];
        }
      })
    );
  }
  get_count_use(): Observable<any> {
    return this.httpClient.get<any>(this.notification_update_service_endpoint_count + 'count').pipe(
      map((res: any) => {
        if (res.status == 200) {
          // console.log(res);
          return res['data'];
        }
      })
    );
  }
  activate_transfer(id: any): Observable<any> {
    return this.httpClient
      .patch<any>(this.notification_update_service_endpoint_count +id +'/seen',{id:id}).pipe(
        map((res: any) => {
         
            return res;
         
        })
      );
  }
  receiveMessage_() {
    this.angularFireMessaging.messages.subscribe(
      (_messaging: any) => {
        console.log("notificationss")
        _messaging.onMessage = _messaging.onMessage.bind(_messaging);
        _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);

      }
    );
  }
  receiveMessage() {
    this.angularFireMessaging.messages.subscribe((payload) => {
      console.log("new message received. ", payload);
      this.currentMessage.next(payload);
    });
  }
  requestPermission() {
    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        console.log(token);
        console.log("fcm " , token);

        this.httpClient
          .post(this.Endpoint_fcm+'/ar', {
            fcm: token,
          })
          .subscribe((res) => {
            console.log("okkk ", res);
          });
      },
      (err) => {
        console.error("Unable to get permission to notify.", err);
      }
    );
  }
}
