import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { AuthentificationService } from 'app/services/authentification.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
constructor(private authService: AuthentificationService, private router: Router) { }
  canActivate() {
    let isAuth = this.authService.isLoggedin();
    console.log("isAuth guard",isAuth)
    if (isAuth) {
      return true ;
    }
    this.router.navigate(['/pages/login'],{ skipLocationChange: true });
    return false;
  }
}
