export const environment = {
  production: true,
  // baseUrl:"https://takafol.itgate-group.com/api",
  baseUrl: "https://dev-tkaful-api.talentsgates.website/api",

  // baseUrl:"http://192.168.1.157:3004/api",
  picture:"https://dev-tkaful-api.talentsgates.website/api/getimage/",
  firebaseConfig : {
    apiKey: "AIzaSyA6QS2myM07derfaBGOchyfFByTwAddPxg",
    authDomain: "takafol-cc818.firebaseapp.com",
    projectId: "takafol-cc818",
    storageBucket: "takafol-cc818.appspot.com",
    messagingSenderId: "117637649258",
    appId: "1:117637649258:web:d31d5f2b00df22b9278b13",
    measurementId: "G-BMKMDF3F6T"
  }
};
