import { Routes, RouterModule } from '@angular/router';

//Route for content layout with sidebar, navbar and footer.

export const Full_ROUTES: Routes = [
  {
    path: 'dashboard',
    loadChildren: () => import('../../dashboard/dashboard.module').then(m => m.DashboardModule)
  },

   {
    path: 'forms',
    loadChildren: () => import('../../forms/forms.module').then(m => m.FormModule)
  },
  {
    path: 'tables',
    loadChildren: () => import('../../tables/tables.module').then(m => m.TablesModule)
  },
  {
    path: 'datatables',
    loadChildren: () => import('../../data-tables/data-tables.module').then(m => m.DataTablesModule)
  },
  {
    path: 'uikit',
    loadChildren: () => import('../../ui-kit/ui-kit.module').then(m => m.UIKitModule)
  },
  {
    path: 'components',
    loadChildren: () => import('../../components/ui-components.module').then(m => m.UIComponentsModule)
  },

  {
    path: 'cardss',
    loadChildren: () => import('../../cardss/cards.module').then(m => m.CardsModule)
  },
/*  ********************************************************************************************************************************************* */
  {
    path: "management",
    loadChildren: () =>
      import("../../page/page.module").then((m) => m.PageModule),
  },
  {
    path: "sponsoring",
    loadChildren: () =>
      import("../../sponsoring/sponsoring.module").then((m) => m.SponsoringModule),
  },
  {
    path: "serviceprovider",
    loadChildren: () =>
      import("../../serviceprovider/serviceprovider.module").then((m) => m.ServiceproviderModule),
  },
  {
    path: "companies",
    loadChildren: () =>
      import("../../companies/companies.module").then((m) => m.CompaniesModule),
  },
  {
    path: "users",
    loadChildren: () =>
      import("../../users/users.module").then((m) => m.UsersModule),
  },
  {
    path: "stock",
    loadChildren: () =>
      import("../../stock/stock.module").then((m) => m.StockModule),
  },
  {
    path: "client",
    loadChildren: () =>
      import("../../client/client.module").then((m) => m.ClientModule),
  },
  {
    path: "roles",
    loadChildren: () =>
      import("../../roles/roles.module").then((m) => m.RolesModule),
  },
  {
    path: "countries",
    loadChildren: () =>
      import("../../countrys/countrys.module").then((m) => m.CountrysModule),
  },
  {
    path: "pays",
    loadChildren: () =>
      import("../../pays/pays.module").then((m) => m.PaysModule),
  },

  {
    path: "cities",
    loadChildren: () =>
      import("../../cities/cities.module").then((m) => m.CitiesModule),
  },
  {
    path: "coins",
    loadChildren: () =>
      import("../../coins/coins.module").then((m) => m.CoinsModule),
  },
  {
    path: "bank",
    loadChildren: () =>
      import("../../bank/bank.module").then((m) => m.BankModule),
  },
  {
    path: "contracts",
    loadChildren: () =>
      import("../../contracts/contracts.module").then((m) => m.ContractsModule),
  },
  {
    path: "inventory",
    loadChildren: () =>
      import("../../inventory/inventory.module").then((m) => m.InventoryModule),
  },  {
    path: "socialmedia",
    loadChildren: () =>
      import("../../socialmedia/socialmedia.module").then((m) => m.SocialmediaModule),
  },

  
  {
    path: "employees",
    loadChildren: () =>
      import("../../employees/employees.module").then((m) => m.EmployeesModule),
  },
  {
    path: "jobs",
    loadChildren: () =>
      import("../../jobs/jobs.module").then((m) => m.JobsModule),  
  },

  {
    path: "missions",
    loadChildren: () =>
      import("../../validite/validite.module").then((m) => m.ValiditeModule),
  },
  {
    path: "ads",
    loadChildren: () => import("../../ads/ads.module").then((m) => m.AdsModule),
  },

  {
    path: "news",
    loadChildren: () =>
      import("../../news/news.module").then((m) => m.NewsModule),
  },

  {
    path: "cards",
    loadChildren: () =>
      import("../../cards/cards.module").then((m) => m.CardsModule),
  },
  {
    path: "coupons",
    loadChildren: () =>
      import("../../coupons/coupons.module").then((m) => m.CouponsModule),
  },
  {
    path: "employment",
    loadChildren: () =>
      import("../../employment/employment.module").then((m) => m.EmploymentModule),
  },
  {
    path: "setting",
    loadChildren: () =>
      import("../../setting/setting.module").then((m) => m.SettingModule),
  },
  {
    path: "complains",
    loadChildren: () =>
      import("../../complains/complains.module").then((m) => m.ComplainsModule),
  },
  {
    path: "notifications",
    loadChildren: () =>
      import("../../notifications/notifications.module").then((m) => m.NotificationsModule),
  },
  {
    path: "service",
    loadChildren: () =>
      import("../../servicemanagement/servicemanagement.module").then((m) => m.ServicemanagementModule),
  },
  
];
