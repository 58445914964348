// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.
//   baseUrl:"http://localhost:3004/api",

export const environment = {
  production: false,
  // baseUrl: "https://takafol.itgate-group.com/api",
  baseUrl: "https://dev-tkaful-api.talentsgates.website/api",
  // baseUrl:"http://192.168.1.157:3004/api",

  picture: "https://dev-tkaful-api.talentsgates.website/api/getimage/",
  // socket_url:"https://takafol.itgate-group.com",
   
  socket_url:"https://dev-tkaful-api.talentsgates.website",
  firebaseConfig: {
    apiKey: "AIzaSyA6QS2myM07derfaBGOchyfFByTwAddPxg",
    authDomain: "takafol-cc818.firebaseapp.com",
    projectId: "takafol-cc818",
    storageBucket: "takafol-cc818.appspot.com",
    messagingSenderId: "117637649258",
    appId: "1:117637649258:web:d31d5f2b00df22b9278b13",
    measurementId: "G-BMKMDF3F6T",
  },
};
