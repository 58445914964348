import { Component, OnInit, Input, ViewChild, OnDestroy, ElementRef, Renderer2, AfterViewInit } from "@angular/core";

import { ROUTES,ROUTES1,ROUTES2,ROUTES3,ROUTES4,ROUTES5,ROUTES6,ROUTES7,ROUTES8,ROUTES9} from './sidebar-routes.config';
import { RouteInfo } from "./sidebar.metadata";
import { Router, ActivatedRoute } from "@angular/router";
import { TranslateService } from '@ngx-translate/core';
import { customAnimations } from "../animations/custom-animations";
import { ConfigService } from '../services/config.service';
import { LayoutService } from '../services/layout.service';
import { Subscription } from 'rxjs';
import { AuthentificationService } from "app/services/authentification.service";
import { AdminService } from "app/services/admin.service";

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  animations: customAnimations
})
export class SidebarComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('toggleIcon', {static: false}) toggleIcon: ElementRef;
  public menuItems: any[];
  depth: number;
  activeTitle: string;
  activeTitles: string[] = [];
  expanded: boolean;
  connect_user: any;
  profile :any=[];
  nav_collapsed_open = false;
  logoUrl = 'assets/img/logo.png';
  logoshortUrl ='assets/img/logo-short.png'
  public config: any = {};
  layoutSub: Subscription;


  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private router: Router,
    private route: ActivatedRoute,
    public translate: TranslateService,
    private configService: ConfigService,
    private layoutService: LayoutService,
    private authentificationservice: AuthentificationService, 
    private adminservice : AdminService
  ) {
    if (this.depth === undefined) {
      this.depth = 0;
      this.expanded = true;
    }

    this.layoutSub = layoutService.customizerChangeEmitted$.subscribe(
      options => {
        if (options) {
          if (options.bgColor) {
            if (options.bgColor === 'white') {
              this.logoUrl = 'assets/img/logo-dark.png';
            }
            else {
              this.logoUrl = 'assets/img/logo.png';
            }
          }

          if (options.compactMenu === true) {
            this.expanded = false;
            this.renderer.addClass(this.toggleIcon.nativeElement, 'ft-toggle-left');
            this.renderer.removeClass(this.toggleIcon.nativeElement, 'ft-toggle-right');
            this.nav_collapsed_open = true;
          }
          else if (options.compactMenu === false) {
            this.expanded = true;
            this.renderer.removeClass(this.toggleIcon.nativeElement, 'ft-toggle-left');
            this.renderer.addClass(this.toggleIcon.nativeElement, 'ft-toggle-right');
            this.nav_collapsed_open = false;
          }

        }
      });

  }


  ngOnInit() {
    this.connect_user = JSON.parse(localStorage.getItem("currentUser"));

    this.config = this.configService.templateConf;
    if(this.connect_user.type_user === "admin"){
      this.menuItems = ROUTES;

    }
    else  if(this.connect_user.type_user === "supplier"){
      this.menuItems = ROUTES1;

    }
    else if(this.connect_user.job.type == 1 ){
      this.menuItems = ROUTES2;

    } else if(this.connect_user.job.type == 2 ){
      this.menuItems = ROUTES3;

    } else if(this.connect_user.job.type == 3 ){
      this.menuItems = ROUTES4;

    } else if(this.connect_user.job.type == 4 ){
      this.menuItems = ROUTES7;

    } else if(this.connect_user.job.type == 5 || this.connect_user.job.type == 7){
      this.menuItems = ROUTES5;

    } else if(this.connect_user.job.type == 6 ){
      this.menuItems = ROUTES6;

    }
   else if(this.connect_user.job.type == 8 ){
    this.menuItems = ROUTES9;

  }
    
    else {
      this.menuItems = ROUTES8;

    }

    this.profilecheck()
    this.profile; 


    if (this.config.layout.sidebar.backgroundColor === 'white') {
      this.logoUrl = 'assets/img/logo-dark.png';
    }
    else {
      this.logoUrl = 'assets/img/logo.png';
    }


  }

  ngAfterViewInit() {

    setTimeout(() => {
      if (this.config.layout.sidebar.collapsed != undefined) {
        if (this.config.layout.sidebar.collapsed === true) {
          this.expanded = false;
          this.renderer.addClass(this.toggleIcon.nativeElement, 'ft-toggle-left');
          this.renderer.removeClass(this.toggleIcon.nativeElement, 'ft-toggle-right');
          this.nav_collapsed_open = true;
        }
        else if (this.config.layout.sidebar.collapsed === false) {
          this.expanded = true;
          this.renderer.removeClass(this.toggleIcon.nativeElement, 'ft-toggle-left');
          this.renderer.addClass(this.toggleIcon.nativeElement, 'ft-toggle-right');
          this.nav_collapsed_open = false;
        }
      }
    }, 0);


  }

  ngOnDestroy() {
    if (this.layoutSub) {
      this.layoutSub.unsubscribe();
    }
  }

  toggleSlideInOut() {
    this.expanded = !this.expanded;
  }

  handleToggle(titles) {
    this.activeTitles = titles;
  }

  // NGX Wizard - skip url change
  ngxWizardFunction(path: string) {
    if (path.indexOf("forms/ngx") !== -1)
      this.router.navigate(["forms/ngx/wizard"], { skipLocationChange: false });
  }
  profilecheck(){
    this.adminservice.getProfile().subscribe(result=>{
      this.profile = result['permissions']
      console.log('current one', this.profile)
    })
  }
  validate_user( type:any){
    return this.profile.filter(el=> el.type==type).length>0 ? true : false}
 is_Admin() {
   return this.connect_user.type_user === "admin" ? true : false;
 }
 is_supplier() {
   return this.connect_user.type_user === "supplier" ? true : false;
 }
 is_Employe_User_sales() {
   return this.connect_user.job.type == 1 ? true : false;
 }
 is_Employe_Manager_Sales() {
   return this.connect_user.job.type == 2 ? true : false;
 }
 is_Employe_Manager_Driver() {
   return this.connect_user.job.type == 3 ? true : false;
 }
 is_Employe_User_Driver() {
   return this.connect_user.job.type == 4 ? true : false;
 }
 is_Employe_user_accounts() {
   return this.connect_user.job.type == 5 ? true : false;
 }
 is_Employe_Contract() {
   return this.connect_user.job.type == 6 ? true : false;
 }
 is_Employe_treasury () {
  return this.connect_user.job.type == 7 ? true : false;
}
 is_Employe_Marketer() {
  return this.connect_user.job.type == 8 ? true : false;
}
}
