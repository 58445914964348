import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Config } from "environments/config";
import { Observable, Subject } from "rxjs";
import { map } from "rxjs/internal/operators";
@Injectable({
  providedIn: "root",
})
export class UsersService {
  user_service_endpoint = Config.serverUrl + "/users/";
  customer_service_endpoint = Config.serverUrl + "/customers/";
  user_missions_service_endpoint = Config.serverUrl + "/missions/";
  user_byid_service_endpoint = Config.serverUrl + "/users/getbyid/";
  user_service_endpoint_status = Config.serverUrl + "/users/account-status/";
  employ_service_endpoint = Config.serverUrl + "/employees/list/";
  all_employe_service_endpoint = Config.serverUrl + "/employees/all/";

  employ_service_addendpoint = Config.serverUrl + "/employees/";
  customer_byid_service_endpoint = Config.serverUrl + "/customers/getbyid/";
  share: Subject<any> = new Subject();
  share$: Observable<any> = this.share.asObservable();
  constructor(private httpClient: HttpClient) {}

  /**
   * Add new user.
   * @body {Role} .
   * @return {Observable<Data>} return data with message and object created.
   */
   shareDate(data:object){
    this.share.next(data);
    console.log('dataa from service', data)
   }
  addNew_user(data): Observable<any> {
    return this.httpClient.post(this.user_service_endpoint, data).pipe(
      map((res: any) => {
        if (res) {
          console.log(res);
          return res;
        }
      })
    );
  }
  addNew_employee(data): Observable<any> {
    return this.httpClient.post(this.employ_service_addendpoint, data).pipe(
      map((res: any) => {
        if (res) {
          console.log(res);
          return res;
        }
      })
    );
  }
  
  get_all_users_salesss(p, per): Observable<any> {
    return this.httpClient
      .get<any>(
        this.user_service_endpoint + "profile/employees/" + p + "/" + per + "/ar"
      )
      .pipe(
        map((res: any) => {
          console.log(res);
          return res["data"];
        })
      );
  }
  check_phone(data): Observable<any> {
    return this.httpClient.post(this.user_service_endpoint+'account-check/phone', data).pipe(
      map((res: any) => {
        if (res) {
          console.log(res);
          return res;
        }
      })
    );
  }
  check_email(data): Observable<any> {
    return this.httpClient.post(this.user_service_endpoint+'account-check/email', data).pipe(
      map((res: any) => {
        if (res) {
          console.log(res);
          return res;
        }
      })
    );
  }
  addNew_employee_sales(data): Observable<any> {
    return this.httpClient
      .post(this.employ_service_addendpoint + "add_employee_user_sales", data)
      .pipe(
        map((res: any) => {
          if (res) {
            console.log(res);
            return res;
          }
        })
      );
  }
  check_membreship(data): Observable<any> {
    return this.httpClient
      .post(this.user_service_endpoint + "check/existence_to_mobile/ar", data)
      .pipe(
        map((res: any) => {
          if (res) {
            console.log("error", res);
            return res;
          }
        })
      );
  }
  /**
   * Get list users.
   * @return {Observable<any>} List placeholder.
   */
  get_all_users(p,pe): Observable<any> {
    return this.httpClient
      .get<any>(this.customer_service_endpoint +'/list/ar/'+ p + "/"+pe)
      .pipe(
        map((res: any) => {
          console.log(res);
          return res["data"];
        })
      );
  }
  
  get_all_empl(): Observable<any> {
    return this.httpClient
      .get<any>(this.customer_service_endpoint + "getall/ar")
      .pipe(
        map((res: any) => {
          if (res.status === 201) {
            console.log(res);
            return res["data"];
          }
        })
      );
  }
  ///getall/ar
  get_all_users_sales(): Observable<any> {
    return this.httpClient
      .get<any>(this.employ_service_addendpoint + "search_by_type/1/ar")
      .pipe(
        map((res: any) => {
          if (res.status === 200) {
            console.log(res);
            return res["data"];
          }
        })
      );
  }
  get_all_users_confirmed(p, per): Observable<any> {
    return this.httpClient
      .get<any>(
        this.customer_service_endpoint + "all/ar/" + p + "/" + per + "/1"
      )
      .pipe(
        map((res: any) => {
          console.log(res);
          return res["data"];
        })
      );
  }
  get_all_users_potential(p, per): Observable<any> {
    return this.httpClient
      .get<any>(
        this.customer_service_endpoint + "all/ar/" + p + "/" + per + "/0"
      )
      .pipe(
        map((res: any) => {
          console.log(res);
          return res["data"];
        })
      );
  }
  get_all_users_subscribed(p, per): Observable<any> {
    return this.httpClient
      .get<any>(
        this.customer_service_endpoint + "all/ar/" + p + "/" + per + "/3"
      )
      .pipe(
        map((res: any) => {
          console.log(res);
          return res["data"];
        })
      );
  }

  get_all_drivers(): Observable<any> {
    return this.httpClient
      .get<any>(this.employ_service_addendpoint + "all_chauffeur/ar")
      .pipe(
        map((res: any) => {
          if (res.status === 200) {
            console.log(res);
            return res["data"];
          }
        })
      );
  }
  /**
   * Get list users.
   * @return {Observable<any>} List placeholder.
   */
  get_all_employees(p, pe): Observable<any> {
    return this.httpClient
      .get<any>(this.employ_service_endpoint + "ar/" + p + "/" + pe)
      .pipe(
        map((res: any) => {
          if (res.status === 200) {
            console.log(res);
            return res["data"];
          }
        })
      );
  }

  filtering_employe(p,data): Observable<any> {
    return this.httpClient.post(this.employ_service_addendpoint + 'filter/ar/'+p, data).pipe(
      map((res: any) => {
        // if (res) {
        //   console.log(res);
        return res
        // }
      })
    );
  }
  get_all_employees_perpage(p, pe): Observable<any> {
    return this.httpClient
      .get<any>(this.employ_service_endpoint + "ar/" + p + "/" + pe)
      .pipe(
        map((res: any) => {
          if (res.status === 200) {
            console.log(res);
            return res["data"];
          }
        })
      );
  }
  delete_employeee(id): Observable<any> {
    return this.httpClient.delete<any>(this.employ_service_addendpoint + id).pipe(
      map((res: any) => {
        if (res.status === 200) {
          console.log(res);
          return res["data"];
        }
      })
    );
  }
  delete_employee(id): Observable<any> {
    return this.httpClient.post<any>(this.employ_service_addendpoint +'freeze_employe/'+ id,{}).pipe(
      map((res: any) => {
        if (res.status === 200) {
          console.log(res);
          return res["data"];
        }
      })
    );
  }
  get_all_emp(): Observable<any> {
    return this.httpClient
      .get<any>(this.all_employe_service_endpoint + "ar")
      .pipe(
        map((res: any) => {
          if (res.status === 200) {
            console.log(res);
            return res["data"];
          }
        })
      );
  }

  get_user_byid(id): Observable<any> {
    return this.httpClient
      .get<any>(this.user_service_endpoint + id + "/ar")
      .pipe(
        map((res: any) => {
          if (res.status === 200) {
            console.log(res);
            return res["data"];
          }
        })
      );
  }
  get_user_profile(): Observable<any> {
    return this.httpClient
      .get<any>(this.user_service_endpoint + "profile/ar")
      .pipe(
        map((res: any) => {
          if (res.status === 200) {
            console.log(res);
            return res["data"];
          }
        })
      );
  }
  get_currentuser_byid(id): Observable<any> {
    return this.httpClient.get<any>(this.user_byid_service_endpoint + id).pipe(
      map((res: any) => {
        if (res.status === 200) {
          console.log(res);
          return res["data"];
        }
      })
    );
  }
  get_customer_byid(id): Observable<any> {
    return this.httpClient
      .get<any>(this.customer_byid_service_endpoint + id + "/ar")
      .pipe(
        map((res: any) => {
          if (res.status === 200) {
            console.log(res);
            return res["data"];
          }
        })
      );
  }

  delete_user(id): Observable<any> {
    return this.httpClient.delete<any>(this.user_service_endpoint + id).pipe(
      map((res: any) => {
        if (res.status === 200) {
          console.log(res);
          return res["data"];
        }
      })
    );
  }

  update_user(id, data): Observable<any> {
    return this.httpClient.put(this.user_service_endpoint + id, data).pipe(
      map((res: any) => {
        if (res) {
          console.log(res);
          return res;
          // if (res.code === HttpStatus.OK) {
          //   return true;
          // }
        }
      })
    );
  }
  update_status(id, data): Observable<any> {
    return this.httpClient.patch(this.user_service_endpoint + id, data).pipe(
      map((res: any) => {
        if (res) {
          console.log(res);
          return res;
        }
      })
    );
  }
  getProfile(): Observable<any> {
    return this.httpClient
      .get<any>(this.user_missions_service_endpoint + "ar/1")
      .pipe(
        map((res: any) => {
          return res["data"];
        })
      );
  }
  get_employe_byid(id): Observable<any> {
    return this.httpClient
      .get<any>(this.employ_service_addendpoint + "getbyid/" + id + "/ar")
      .pipe(
        map((res: any) => {
          if (res.status === 200) {
            console.log(res);
            return res["data"];
          }
        })
      );
  }
  update_employe(id, data): Observable<any> {
    return this.httpClient.put(this.employ_service_addendpoint + id, data).pipe(
      map((res: any) => {
        console.log("okkkk", res);

        console.log(res);
        return res;
        // if (res.code === HttpStatus.OK) {
        //   return true;
        // }
      })
    );
  }
  get_all_mission(): Observable<any> {
    return this.httpClient
      .get<any>(this.user_missions_service_endpoint + "all_incoming_missions_employee/ar/1/100")
      .pipe(
        map((res: any) => {
          if (res.status === 200) {
            console.log(res);
            return res["data"];
          }
        })
      );
  }
  get_all_mission_byId(id: any): Observable<any> {
    return this.httpClient
      .get<any>(this.user_missions_service_endpoint + "incoming_missions_employee/"+id+"/ar/1/100")
      .pipe(
        map((res: any) => {
          if (res.status === 200) {
            console.log(res);
            return res["data"];
          }
        })
      );
  }
  get_all_mission_Outgoing(): Observable<any> {
    return this.httpClient
      .get<any>(this.user_missions_service_endpoint + "all_missions_employee/ar/1/100")
      .pipe(
        map((res: any) => {
          if (res.status === 200) {
            console.log(res);
            return res["data"];
          }
        })
      );
  }
  get_all_mission_Outgoing_byId(id: any): Observable<any> {
    return this.httpClient
      .get<any>(this.user_missions_service_endpoint + "/user/all_missions_employee/"+id+"/ar/1/100")
      .pipe(
        map((res: any) => {
          if (res.status === 200) {
            console.log(res);
            return res["data"];
          }
        })
      );
  }
  filtering_user(p,data): Observable<any> {
    console.log('dataaa::',data)
    return this.httpClient.post(this.employ_service_addendpoint + 'filter/ar/'+p, data).pipe(
      map((res: any) => {
        // if (res) {
          console.log(res);
        return res
        // }
      })
    );
  }
  filtering_userr(data): Observable<any> {
    console.log('dataaa::',data)
    return this.httpClient.post(this.employ_service_addendpoint + 'normal/filter/ar', data).pipe(
      map((res: any) => {
        // if (res) {
          console.log(res);
        return res
        // }
      })
    );
  }
  filtering_customer(p,data): Observable<any> {
    return this.httpClient.post(this.customer_service_endpoint + 'withoutstatus/filter/ar/'+p, data).pipe(
      map((res: any) => {
        // if (res) {
          console.log(res);
        return res
        // }
      })
    );
  }
  filtering_customer_potential(p,data): Observable<any> {
    return this.httpClient.post(this.customer_service_endpoint + 'filter/ar/'+p+'/0', data).pipe(
      map((res: any) => {
        // if (res) {
          console.log(res);
        return res
        // }
      })
    );
  }
  filtering_customer_confirmed(p,data): Observable<any> {
    return this.httpClient.post(this.customer_service_endpoint + 'filter/ar/'+p+'/1', data).pipe(
      map((res: any) => {
        // if (res) {
          console.log(res);
        return res
        // }
      })
    );
  }
  activate_customer(id: any): Observable<any> {
    return this.httpClient
      .post<any>(this.customer_service_endpoint + "confirmed_customer/" + id, {})
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

}
