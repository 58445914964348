import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { BehaviorSubject, Observable } from "rxjs";
import { map, tap } from "rxjs/internal/operators";
import { environment } from "../../environments/environment";
import { NotificationsService } from "./notifications.service";
import Swal from "sweetalert2";

@Injectable({
  providedIn: "root",
})
export class AuthentificationService {
  private JWT_TOKEN = "JWT_TOKEN";
  private REFRESH_TOKEN = "REFRESH_TOKEN";
  private Endpoint_login = environment.baseUrl + "/users/login";
  private Endpoint_REFRESH_TOKEN = environment.baseUrl + "/users/refresh-token";
  private currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;
  constructor(
    private httpClient: HttpClient,
    private messagingService: NotificationsService,
    private router: Router
  ) 
  {
    this.currentUserSubject = new BehaviorSubject<any>(
      JSON.parse(localStorage.getItem("currentUser"))
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }
  get currentUserValue(): any {
    return this.currentUserSubject.value;
  }

  login(context: any) {
    const loginRequest = {
      email: context.email,
      password: context.password,
    };
    return this.httpClient.post(this.Endpoint_login + "/en", loginRequest).pipe(
      map(
        (user) => {
          if (user["data"]["user"].type_user === "customer") {
            Swal.fire({
              position: "center",
              icon: "error",
              title: "آسف...",
              showConfirmButton: false,

              text: "حدث خطأ ما في تفاصيل تسجيل الدخول الخاصة بك!",
              timer: 2000,
            });
          
          }
          else{
          localStorage.clear();
          //refresh_token
          localStorage.setItem(
            this.REFRESH_TOKEN,
            user["data"]["refresh_token"]
          );
          localStorage.setItem(
            this.JWT_TOKEN,
            user["data"]["token"]
          );

          localStorage.setItem("user_name", user["data"]["user"].user_name);
          localStorage.setItem(
            "currentUser",
            JSON.stringify(user["data"]["user"])
          );
          this.currentUserSubject.next(user["data"]["user"]);
       
          this.messagingService.requestPermission();
          window.location.reload();
           this.router.navigate(["/management"]);
        }},
        (error) => {
          console.log('ereeeurr',error.error);
        }
      )
    );
  }

  LogOut() {
    localStorage.clear();
    this.currentUserSubject.next(null);

    this.router.navigate(["/pages/login"]);
  }
  isLoggedin() {
    return !!this.getJwtToken();
  }
  getJwtToken() {
    return localStorage.getItem(this.JWT_TOKEN);
  }
 

  removeToken() {
    localStorage.removeItem(this.JWT_TOKEN);
    localStorage.removeItem(this.REFRESH_TOKEN);
    localStorage.removeItem("currentUser");
  }

  refreshToken() {
    console.log('before refresh ',localStorage.getItem(this.REFRESH_TOKEN)) 
    return this.httpClient.post<any>(this.Endpoint_REFRESH_TOKEN, {
      refresh_token:localStorage.getItem(this.REFRESH_TOKEN)});
  }
}
