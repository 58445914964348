import { NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { CommonModule, DatePipe } from '@angular/common';

import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { AppRoutingModule } from "./app-routing.module";
import { SharedModule } from "./shared/shared.module";
import { ToastrModule } from "ngx-toastr";
import { AgmCoreModule } from "@agm/core";
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from "@angular/common/http";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { StoreModule } from "@ngrx/store";

import { 
  PerfectScrollbarModule, 
  PERFECT_SCROLLBAR_CONFIG, 
  PerfectScrollbarConfigInterface
} from 'ngx-perfect-scrollbar';
import { AngularFireMessaging } from '@angular/fire/messaging';

import { AppComponent } from "./app.component";
import { ContentLayoutComponent } from "./layouts/content/content-layout.component";
import { FullLayoutComponent } from "./layouts/full/full-layout.component";
import { DragulaService } from "ng2-dragula";
import {TabsModule} from 'ngx-bootstrap/tabs';
import { WINDOW_PROVIDERS } from "./shared/services/window.service";
import { AuthInterceptor } from "./interceptor/auth.interceptor";
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import {Ng2TelInputModule} from 'ng2-tel-input';
import { AngularFireModule } from '@angular/fire';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { DeviceDetectorModule } from "ngx-device-detector";
import { NgxSpinnerModule } from "ngx-spinner";
import { environment } from "environments/environment";
import { SocketIoConfig, SocketIoModule } from "ngx-socket-io";

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelPropagation: false
};

const user :any = JSON.parse(localStorage.getItem("currentUser"))
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json")
}

// const config: any = { url:environment.socket_url,
//   options: {
//   query:{
//     user_id:user!=null?user._id:''
//   },

//   transports: ['websocket'],
//   rejectUnauthorized:false
// }}


const config: any = { url:environment.socket_url,
  options: {
  query:{
    user_id:user!=null?user._id:'',
  },
  transports: ['websocket'], 
  allowUpgrades : true,
  secure:true,
  forceNew: true,
  rejectUnauthorized:false
}
};

@NgModule({
  declarations: [AppComponent, FullLayoutComponent, ContentLayoutComponent],
  imports: [

    HttpClientModule,
    PerfectScrollbarModule,
    NgbModule,
    NgxSpinnerModule,
    DeviceDetectorModule.forRoot(),
    HttpClientModule,
    GooglePlaceModule,
    Ng2TelInputModule,
    BrowserAnimationsModule,
    StoreModule.forRoot({}),
    AppRoutingModule,
    SharedModule,
    HttpClientModule,
    ToastrModule.forRoot(),
    TabsModule.forRoot(),
    NgbModule.forRoot(),
    SocketIoModule.forRoot(config),

    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    AgmCoreModule.forRoot({
      apiKey: "YOUR KEY"
    }),
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    PerfectScrollbarModule
  ],
  providers: [

    DragulaService ,DatePipe,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    { provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    WINDOW_PROVIDERS,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
